import { useState , useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
//importing components
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";

//importing images
import siteLogo from "../../assets/images/newSiteLogo.webp";
import icon from "../../assets/images/Auth/sign-up-icon1.webp";
import wwwIcon from "../../assets/images/Navbar/wwwIcon.svg";


//importing styles
import "../../assets/scss/pages/auth/signUp/signUp.scss";
import Dropdown from "../../components/common/dropdown/Dropdown";
import PasswordStrength from "../../components/common/passwordStrength/PasswordStrength";
import InputWithPhoneCode from "../../components/common/input/InputWithPhoneCode";

//import services
import { signUpUser } from "../../services/auth.service";
import { setUserSession, updateUserSession } from "../../utils/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { selectCountryList, setToken } from "../../redux/userSlice";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { use } from "react";


const SignUp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const countryList = useSelector(selectCountryList);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCountryId , setSelectedCountryId]= useState()
     //Translation
     const [translatedTexts, setTranslatedTexts] = useState({});
     const [targetLanguage, setTargetLanguage] = useState(); // Example: Spanish
     const googleApiKey = 'AIzaSyDbDmFBTX5AzcgRncXn_Tdm4PQX0ymFoTU'; // Replace with your actual Google Cloud API key
    
    // Initialize useSearchParams
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Reskills | Sign Up"
    }, []);
    // Get the value of referral_code

    // Map countries to their primary language
    const languages = [
        { lang: 'EN', code: 'en' },
        { lang: 'MS', code: 'ms' },
        { lang: 'IN', code: 'id' },
        // Add more languages if necessary
      ];
      
      
      
    //   useEffect(() => {
    //     const detectUserLanguage = async () => {
    //       try {
    //         const locationResponse = await fetch('https://ipinfo.io/json');
    //         const data = await locationResponse.json();
    //         const countryCode = data.country; // e.g., "US", "IN"
    //         const language = countryLanguageMap[countryCode] || 'en'; // Default to English if not mapped
    //         setTargetLanguage(language); // Ensure setTargetLanguage is correctly defined in your component
    //       } catch (error) {
    //         console.error('Error fetching user location:', error);
    //       }
    //     };
      
    //     detectUserLanguage();
    //   }, []);
      

   
   

    const texts = {
        firstName: 'First Name',
        firstWarning: 'The registered name will be printed on the certificate and cannot be changed. Please make sure the spelling is accurate.' ,
        lastName: 'Last Name',
        email: 'Email Address',
        country : 'Country' ,
        phone_number : 'Phone Number',
        create_password :'Create Password',
        confirm_password : 'Confirm Password',
        keep_me :'Keep me informed about new contents and exciting events.',
        sign_up : 'Sign Up',
        country_error : 'The Country field is required',
        agree : 'I agree with the',
        tnc : 'Terms and Conditions',
        and : 'and',
        pnp : 'Privacy Policy' ,
        error_pp : 'Please check the terms and privacy policy box.' ,
        account_have :  'Already have an account?' ,
        sign_in : 'Sign in',
        register_now : 'Register Now',
        loading : 'Loading',
        pass_match : 'The password field confirmation does not match.' ,
        pass_error : 'The password field is required.',
        phone_error: 'The Phone Number field is required.',
        mail_taken : 'The email has already been taken.' ,
        mail_error : 'The email field is required.',
        lastname_error : 'The last name field is required.',
        firstname_eroor : 'The first name field is required.',
        first_placeholder : 'Enter First Name' ,
        secound_placeholder : 'Enter Last Name',
        email_placeholder : 'Enter your Email',
        country_placholder : 'Select the Country',
        phone_placeholder : 'Enter Phone Number',
        password_placeholder : 'Enter Your Password ',
        why : 'Why learn with',
        user1 : 'ALL IN 1 ACCESS',
        sub_user1 : 'Full access to learning contents and features with no hidden costs.',
        user2 : 'FAST AND IMPACTFUL LEARNING',
        sub_user2 : 'Fast and impactful learning with diverse, high quality learning content.',
        user3 : '365 DAYS NON-STOP LIVE CLASSES',
        sub_user3 : 'Master new skillset through daily interactive LIVE classes' ,
        user4 : 'GLOBAL MASTER COACHES' ,
        sub_user4 : 'Learn from esteemed global coaches and industry experts' ,
        user5 :'EXCLUSIVE EVENTS',
        sub_user5 : 'Exclusive access to special learning events unavailable elsewhere',
        user6 : 'GLOBALLY ACCREDITED CERTIFICATION',
        sub_user6 : 'Boost your career by earning globally accredited certification.',
        passStr1 : 'Your password must contain:',
        passStr2 : 'A lowercase and uppercase letter(a-z;A-Z)',
        passStr3 : 'a number',
        passStr4 : 'a special character($&@!#%^*)',
        phone_err2 : 'Phone number should be greater than 9 and less than 15 digits.',
        passStr5 : 'at least 8 characters',
        

    };
    
    useEffect(() => {
        if (googleApiKey) {
            const translateTexts = async () => {
                try {
                    // Create separate `q` parameters for each text
                    const queryParams = Object.values(texts)
                        .map(text => `q=${encodeURIComponent(text)}`)
                        .join('&');
    
                    const response = await fetch(
                        `https://translation.googleapis.com/language/translate/v2?${queryParams}&target=${targetLanguage}&key=${googleApiKey}`
                    );
    
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
    
                    const result = await response.json();
    
                    // Ensure result contains expected data
                    if (result?.data?.translations) {
                        // Map translated texts back to keys
                        const translatedObject = Object.keys(texts).reduce((acc, key, index) => {
                            acc[key] = result.data.translations[index]?.translatedText || texts[key];
                            return acc;
                        }, {});
    
                        // Update state with translated texts
                        setTranslatedTexts(translatedObject);
                    } else {
                        console.error('Unexpected response format:', result);
                    }
                } catch (error) {
                    console.error('Error translating texts:', error);
                }
            };
    
            translateTexts();
        }
    }, [targetLanguage, googleApiKey ]);
    
    



    //Translation

     // Get the value of referral_code from the search parameters
     const referralCode = searchParams.get('referral_code');

     // Get the value of partner_code
     const partnerCode = searchParams.get('partner_code');

    useEffect(() => {
       

        if (partnerCode) {
            // Save the referralCode in sessionStorage
            sessionStorage.setItem('partner_code', partnerCode);
          }
    
        if (referralCode) {
          // Save the referralCode in sessionStorage
          sessionStorage.setItem('referral_code', referralCode);
        }
      }, [searchParams]);


    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
        news_letter_subscription: 0,
        privacy_policy: false,
        captcha_response: "",
        referral_code: sessionStorage?.getItem('referral_code') || referralCode,
        partner_code : sessionStorage?.getItem('partner_code') || partnerCode
    });
    const [errorMessage, setErrorMessage] = useState({});

    /**
        * @function signUpUser
        * @param { first_name, last_name, email, phone_number, country_code, country_id, password, password_confirmation,captcha_response }
        * @description This function is used to register user
    */
    const handleSignUpUser = async () => {
        setIsLoading(true);
        if (formData) {
            const result = await signUpUser({
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                email: formData?.email,
                phone_number: formData?.phone_number,
                country_code: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code,
                country_id: countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.id,
                password: formData?.password,
                password_confirmation: formData?.password_confirmation,
                news_letter_subscription: formData?.news_letter_subscription,
                privacy_policy: formData?.privacy_policy,
                captcha_response: formData?.captcha_response,
                referral_code: formData?.referral_code,
                partner_code : formData?.partner_code
            });
            if (result?.data?.status === "success") {
                const token = result?.data?.user?.verification_token;
                const user = result?.data?.user?.id;
                const tokens = {
                    tokenAccess: token,
                };
                // dispatch(setToken(tokens?.tokenAccess))
                // setUserSession(tokens, user);
                updateUserSession(user)
                sessionStorage.removeItem('partner_code');
                sessionStorage.removeItem('referral_code');
                navigate("/otp-verification", {
                    state: {
                        type: "register",
                        email: formData?.email,
                        first_name: formData?.first_name,
                        verification_token : token
                    },
                });
            }
            if (result?.response?.data?.errors) {
                setErrorMessage(result?.response?.data?.errors);
                toast.error(result?.response?.data?.message)
            }
        }
        setIsLoading(false);
    };

    /**
        * @function handleOnChange
        * @param { non }
        * @description This function is used to set the input values
    */
    // const handleOnChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    
        // Validate email if the field is 'email'
        if (name === "email") {
            const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            setErrorMessage((prev) => ({
                ...prev,
                email: isValid ? false : ["Please enter a valid email address."],
            }));
        }

        if (name === "phone_number") {
            const isValidPhone = /^\d{10,14}$/.test(value); 
            setErrorMessage((prev) => ({
                ...prev,
                phone_number: isValidPhone ? false : ["Phone number should be greater than 9 and less than 15 digits."],
            }));
        }


        if (name === "first_name") {
            const isValidFirstName = value
            setErrorMessage((prev) => ({
                ...prev,
                first_name: isValidFirstName ? false : ["The first name field is required."],
            }));
        }

        if (name === "last_name") {
            const isValidFirstName = value
            setErrorMessage((prev) => ({
                ...prev,
                last_name: isValidFirstName ? false : ["The last name field is required."],
            }));
        }

    };

    useEffect(() => {
        if(formData?.password === formData?.password_confirmation){
            setErrorMessage((prev) => ({
                ...prev,
                password_confirmation: false,
                password: false,
            }));
        }else{ 
            setErrorMessage((prev) => ({
                ...prev,
                password_confirmation: ["The password field confirmation does not match."],
            }));
        }
    }, [formData?.password_confirmation , formData?.password]);

    useEffect(() => {
       if (selectedCountry) {
        setErrorMessage((prev) => ({
            ...prev,
                country_code: selectedCountry ? false : ["The country code field is required."],
            }));
        }
        
    }, [selectedCountry]);

    console.log("selectedCountry", selectedCountry);
    

    /**
        * @function handleCheckBox
        * @param { non }
        * @description This function is used to set the checkbox values
    */
    const handleCheckBox = (e) => {
        const { name, checked } = e.target;
        if (name === "news_letter_subscription") {
            setFormData({
                ...formData,
                [name]: checked === true ? "1" : "0",
            });
        } else {
            setFormData({
                ...formData,
                [name]: checked,
            });
        }
    };

    /**
        * @function onChange
        * @param {  recaptcha token }
        * @description This function is used to set generated recaptcha token
    */
    function onChange(value) {
        setFormData({
            ...formData,
            captcha_response: value,
        });
    }

    return (
        <div className="custom-container max-w-home">
            <div className="d-flex justify-content-end">
                <div className="dropdown-country-container mx-4 my-1">
                    <img
                        src={wwwIcon}
                        alt="www-icon"
                        className="dropdown-menu-icon"
                    />
                    <select
                        className="dropdown-itemMenu"
                        onChange={(e) => setTargetLanguage(e.target.value)}
                    >
                        {languages.map((item, index) => (
                        <option
                            key={index}
                            className="dropdown-items"
                            value={item.code}
                        >
                            {item.lang}
                        </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="signUp-main-container d-flex">
                <div className="signUp-left-container">
                
                

                    <div className="text-center">
                        <Link to="/">
                            <img src={siteLogo} alt="siteLogo" className="mb-4" />
                        </Link>

                        <h2 className="fw-bold fs-3">{translatedTexts?.sign_up||'Sign Up'}</h2>
                    </div>
                    <div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.first_name && "errorColor"}   fs-6`}>
                               {translatedTexts?.firstName ||'First Name'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.first_name && "error-border"} `}
                                name="first_name"
                                placeholder={translatedTexts?.first_placeholder ||"Enter first name"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.first_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {translatedTexts?.firstname_eroor || errorMessage?.first_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                {translatedTexts?.firstWarning  || 'The registered name will be printed on the certificate and cannot be changed. Please make sure the spelling is accurate.'}
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.last_name && "errorColor"}   fs-6`}>
                                {translatedTexts?.lastName|| 'Last Name'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.last_name && "error-border"} `}
                                name="last_name"
                                placeholder={translatedTexts?.secound_placeholder ||"Enter last name"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.last_name && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    { translatedTexts?.lastname_error|| errorMessage?.last_name[0]}
                                </p>
                            )}
                            <p className="m-0 text-secondary fs-6">
                                {translatedTexts?.firstWarning  || 'The registered name will be printed on the certificate and cannot be changed. Please make sure the spelling is accurate.'}
                            </p>
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.email && "errorColor"}  fs-6`}>
                                {translatedTexts?.email || 'Email'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.email && "error-border"} `}
                                name="email"
                                placeholder={translatedTexts?.email_placeholder ||"Enter your email"}
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.email && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    { errorMessage?.email[0] === 'The email field is required.' ? translatedTexts?.mail_error : errorMessage?.email[0] ==='The email has already been taken.' ? translatedTexts?.mail_taken : errorMessage?.email[0] }
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.country_code && "errorColor"} fs-6`}>
                                {translatedTexts?.country ||'Country'} <span className="text-danger">*</span>
                            </label>
                            <Dropdown
                                options={countryList}
                                defaultOption={translatedTexts?.country_placholder ||"Select the country"}
                                setSelectedCountry={setSelectedCountry}
                                setSelectedCountryId={setSelectedCountryId }
                            />

                            {errorMessage?.country_code && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {translatedTexts?.country_error || 'The Country field is required'}
                                </p>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className={`${errorMessage?.phone_number && "errorColor"} fs-6`}>
                                {translatedTexts?.phone_number || 'Phone Number'} <span className="text-danger">*</span>
                            </label>
                            <InputWithPhoneCode
                                name="phone_number"
                                errorClass={`${errorMessage?.phone_number && "err-border"} `}
                                errorInClass={`${errorMessage?.phone_number && "error-border"} `}
                                placeholder ={translatedTexts?.phone_placeholder}
                                phoneCode={
                                    selectedCountry && countryList?.find(item => parseInt(item?.id) === parseInt(selectedCountry))?.isd_code
                                }
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.phone_number && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {
                                        (errorMessage?.phone_number[0] === "Phone number should be greater than 9 and less than 15 digits."
                                        ? translatedTexts?.phone_err2
                                        : errorMessage?.phone_number[0] === 'The phone number field is required.'
                                        ? translatedTexts?.phone_error
                                        : errorMessage?.phone_number[0]) || errorMessage?.phone_number[0]
                                    }
                                </p>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className={`${errorMessage?.password && "errorColor"} fs-6`}>
                                {translatedTexts?.create_password || 'Create Password'} <span className="text-danger">*</span>
                            </label>
                            <Input
                                errorClass={`${errorMessage?.password && "error-border"} `}
                                name="password"
                                placeholder={ translatedTexts?.password_placeholder || "Enter your password"}
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password[0] === 'The password field is required.' ? translatedTexts?.pass_error:errorMessage?.password[0] === 'The password field confirmation does not match.' ? translatedTexts?.pass_match : errorMessage?.password[0]}
                                </p>
                            )}
                            {formData?.password && (
                                <PasswordStrength 
                                    password={formData?.password}
                                    passStr1 ={translatedTexts?.passStr1}
                                    passStr2 ={translatedTexts?.passStr2}
                                    passStr3 ={translatedTexts?.passStr3}
                                    passStr4 ={translatedTexts?.passStr4}
                                    passStr5 = {translatedTexts?.passStr5}
                                />
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="fs-6">{translatedTexts?.confirm_password|| 'Confirm Password'} <span className="text-danger">*</span></label>
                            <Input
                                name="password_confirmation"
                                placeholder={ translatedTexts?.password_placeholder || "Enter your password"}
                                type="password"
                                onChange={(e) => handleOnChange(e)}
                            />
                            {errorMessage?.password_confirmation && (
                                <p style={{ fontSize: "13px", color: "#f04438" }}>
                                    {errorMessage?.password_confirmation[0]}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3 mt-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="news_letter_subscription"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">{translatedTexts?.keep_me || 'Keep me informed about new contents and exciting events.'}</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check d-flex gap-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name="privacy_policy"
                                onChange={handleCheckBox}
                            />
                            <p className="m-0 fs-6 fw-normal">
                                {translatedTexts?.agree  || 'I agree with the'} <Link className="text-decoration-none" to="/terms">{ translatedTexts?.tnc || 'Terms and Conditions'}</Link> { translatedTexts?.and ||'and'} <Link className="text-decoration-none" to="/privacy"> { translatedTexts?.pnp || 'Privacy Policy'}</Link>
                            </p>
                        </div>
                        {errorMessage?.privacy_policy && (
                            <p style={{ fontSize: "13px", color: "#f04438" }}>
                                { translatedTexts?.error_pp || 'Please check the terms and privacy policy box.'}
                            </p>
                        )}
                    </div>
                    <div className="my-3">
                        <ReCAPTCHA
                            sitekey="6LcxA54pAAAAAG756fqqQSy621XWQ3wk5CTgDvYE"
                            onChange={onChange}
                        />
                    </div>
                    <Button
                        name={`${isLoading ? ( translatedTexts?.loading|| "Loading...") : ( translatedTexts?.register_now ||"Register Now") }`}
                        onClick={handleSignUpUser}
                        disable={(formData?.captcha_response ? false : true) || isLoading || !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(formData.password)}
                    />
                    <div className="mt-3 text-center">
                        <p className="text-secondary fs-6">
                            {translatedTexts?.account_have  || 'Already have an account?'}{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={() => navigate("/login")}
                            >
                                { translatedTexts?.sign_in || 'Sign in'}
                            </span>
                        </p>
                    </div>
                </div>
                <div className="signUp-right-container">
                    <div className="d-flex align-items-center mb-3">
                        <h3 className="fw-bold mb-0 me-2">{ translatedTexts?.why||'Why learn with'}</h3>
                        <Link to='/'>
                            <img src={siteLogo}  alt="siteLogo" />
                        </Link>
                    </div>
                    <div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'50px'}} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">{translatedTexts?.user1 ||'ALL IN 1 ACCESS'}</h5>
                                {translatedTexts?.sub_user1 ?
                                    <p className="text-secondary fs-6">
                                        {translatedTexts?.sub_user1}
                                    </p>:
                                    <p className="text-secondary fs-6">
                                    Full access to learning contents and features <br /> with no
                                    hidden costs.
                                </p>
                                    }
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'50px'}} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">{ translatedTexts?.user2|| 'FAST AND IMPACTFUL LEARNING'}</h5>
                                
                               {translatedTexts?.sub_user2 ?
                                <p className="text-secondary fs-6">
                                    {translatedTexts?.sub_user2}
                                </p>:
                                <p className="text-secondary fs-6">
                                    Fast and impactful learning with diverse, high<br /> quality learning content.
                                </p>
                                }
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'50px'}} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">{translatedTexts?.user3||'365 DAYS NON-STOP LIVE CLASSES'}</h5>

                                {translatedTexts?.sub_user3 ?
                                    <p className="text-secondary fs-6">
                                    {translatedTexts?.sub_user3}
                                </p>:
                                <p className="text-secondary fs-6">
                                Master new skillset through daily interactive LIVE<br /> classes
                            </p>
                                }
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'50px'}} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">{ translatedTexts?.user4||'GLOBAL MASTER COACHES'}</h5>
                                {translatedTexts?.sub_user4
                                    ?<p className="text-secondary fs-6">
                                   {translatedTexts?.sub_user4}
                                </p>:
                                <p className="text-secondary fs-6">
                                Learn from esteemed global coaches and <br />industry experts
                            </p>
                                }
                            </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'0px'}} alt="icon" />
                                <hr className="vertical-line" />
                            </div>
                            <div>
                                <h5 className="fw-bold">{ translatedTexts?.user5||'EXCLUSIVE EVENTS'}</h5>
                                {translatedTexts?.sub_user5 ?
                                <p className="text-secondary fs-6">
                                    {translatedTexts?.sub_user5}
                                </p>
                                :
                                <p className="text-secondary fs-6">
                                    Exclusive access to special learning events<br /> unavailable elsewhere
                                </p>
                                }
                            </div>
                        </div>
                        <div className="d-flex gap-2">
                            <div>
                                <img src={icon} style={{width : '50px' , height :'50px'}} alt="icon" />
                                {/* <hr className="vertical-line" /> */}
                            </div>
                            <div>
                                {translatedTexts?.user6?
                                <h5 className="fw-bold">{translatedTexts?.user6}</h5>
                                :<h5 className="fw-bold">GLOBALLY ACCREDITED <br />CERTIFICATION</h5>}
                                {translatedTexts?.sub_user6 ?
                                <p className="text-secondary fs-6">
                                    {translatedTexts?.sub_user6}
                                </p>
                                :<p className="text-secondary fs-6">
                                Boost your career by earning globally<br /> accredited certification.
                            </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-5 fs-6 mx-3">
                © Reskills 2021-2025
            </div>
        </div>
    );
}

export default SignUp;
